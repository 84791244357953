<script setup>
import { useDisplay } from "vuetify";
const display = useDisplay();

const props = defineProps({
  blok: Object,
  sectionBlock: Object,
});
const { useCases, link } = await useUseCases();
const config = await useConfig();
const { parse } = useUtils();
const currentSlide = ref(0);
</script>

<template>
  <div v-editable="blok" class="feature-bento-box pb-0 relative">
    <div class="d-flex justify-end relative" style="z-index: 10">
      <div class="cta-tabs-menu w-tab-menu" role="tablist">
        <button
          v-for="(useCase, i) in useCases"
          :key="useCase.slug"
          class="cta-tab-link w-inline-block w-tab-link"
          :class="{ 'w--current': currentSlide === i }"
          href="#"
          role="tab"
          aria-controls="w-tabs-0-data-w-pane-0"
          :aria-selected="currentSlide === i ? 'true' : 'false'"
          @click="currentSlide = i"
        >
          <div>{{ useCase.name }}</div>
        </button>
      </div>
    </div>

    <div
      v-for="(useCase, i) in useCases"
      v-show="currentSlide === i"
      :key="useCase.slug"
      class="cta-wrapper"
      :class="[useCase.slug]"
    >
      <div data-figma-id="440:403" class="cta-content-holder">
        <strong>{{ parse(useCase.content.teaser_headline) }}</strong>
        <h2 class="cta-title">
          {{ useCase.content.pre_headline }}
        </h2>
        <div class="text-wrap">
          <p>{{ useCase.content.description }}</p>
        </div>
        <div class="cta-buttons mt-8 mb-10">
          <MyLink button v-bind="link(useCase)" />
          <MyLink
            :link="{ url: '@showSchedulePopup', linktype: 'url' }"
            :link-text="config.contact_cta[0].button_text"
            :button="false"
          >
          </MyLink>
        </div>
      </div>
      <div data-figma-id="440:404" class="cta-tabs-wrap">
        <NuxtImg
          v-if="useCase.content.image"
          height="500"
          :src="
            useCase.content.image.filename || '../../images/Old_Man_King.png'
          "
          :alt="useCase.content.image.alt"
          loading="lazy"
          class="image home-challenge-slider-image pt-4"
        />
      </div>
    </div>
    <BgGradient
      :position="display.smAndDown.value ? 'left' : 'right'"
      :width="90"
    />
  </div>
</template>

<style scoped lang="scss">
.image.home-challenge-slider-image {
  margin: 0 auto;
  object-fit: cover;
  object-position: left bottom;
}
.cta-wrapper.change-management {
  .image.home-challenge-slider-image {
    object-fit: none;
  }
}

.border-bottom {
  border-bottom: 1px solid #ebebeb;
}
.text-wrap {
  max-width: 550px;
}
</style>
